.flex-container-form-know-you {
  display: flex;
  align-items: stretch;
}

.flex-container-form-know-you-item-right
.flex-container-form-know-you-item-left {
  margin: 10px;
  line-height: 40px;
  font-size: 30px;
}

.flex-container-form-know-you-item-center {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

.flex-container-form-know-you-item-right {
  padding-right: 12.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: right;
}

.flex-container-form-know-you-item-left {
  padding-left: 12.5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
}

.form-know-you-item-input-md,
.form-know-you-item-input-small,
.form-know-you-item-input-large,
.form-know-you-item-input-medium,
.form-know-you-item-input,
.form-know-you-item-input-xs,
.form-know-you-item-input-xxs,
.form-know-you-item-input-mobile,
.form-know-you-item-input-smaller,
.form-know-you-item-input-text-area,
.form-know-you-item-input-text-area-mobile,
.form-know-you-item-input-xxs-mobile,
.form-know-you-item-input-mobile-x2,
.form-know-you-item-input-mobile-x3,
.form-know-you-item-input-smaller-2 {
  height: 34px;
  border-radius: 18px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  padding-left: 8px;
  font-size: initial !important;
}

.input-error {
  border: 1px solid #07A20A !important;
  box-shadow: 0 0 2px #07A20A !important; 
}

.display-flex-cuota {
  display: inline-flex !important;
}

.form-know-you-item-input-large {
  width: 618px;

}
.form-know-you-item-input-md {
  width: 302px;

}
.form-know-you-item-input-mobile {
  width: 260px;

}
.form-know-you-item-input-mobile-x2 {
  width: 330px;

}
.form-know-you-item-input-mobile-x3 {
  width: 400px;

}
.form-know-you-item-input-medium {
  width: 243px;
}

.form-know-you-item-input-small {
  width: 300px;
}
.form-know-you-item-input-xs {
  width: 177px;
}
.form-know-you-item-input-xxs {
  width: 146px;
}
.form-know-you-item-input-xxs-mobile {
  width: 154px;
}
.form-know-you-item-input-smaller {
  width: 219px;
}
.form-know-you-item-input-smaller-2 {
  width: 200px;
}
.form-know-you-item-input-text-area {
  width: 474px;
  height: 76px;
}
.form-know-you-item-input-text-area-mobile {
  width: 319px;
  height: 76px;
}
.subtitle-medium{
  font-size: 15px !important;
    line-height: 20px !important;
}
.esconder{
  display: none !important;
}
.roundNumberFormat{
    border-radius: 18px;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-shadow: 0 0 2px rgb(208 2 27 / 70%);
    font-size: initial !important;
}
.form-know-you-item-button,
.form-know-you-item-note,
.-mobile {
  height: 39px;
  width: 100%;
  border-radius: 31px;
  background: #4FAE32;
  border: 1px solid #D8D8D8;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
}
.form-know-you-item-button-gray,
.form-know-you-item-button {
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  line-height: 33px;
}

.form-know-you-item-note-mobile {
  height: 39px;
  width: 100%;
  border-radius: 31px;
  background: #4FAE32;
  border: 1px solid #D8D8D8;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
}
.form-know-you-item-note {
  width: 582px;
  font-size: 22px;
  line-height: 33px;
}
.form-know-you-item-button-gray {
  height: 39px;
  width: 234px;
  border-radius: 31px;
  background: #1059A9;
  border: 1px solid #D8D8D8;
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  display: inline-block;
}

.form-know-you-item-button:hover{
  opacity: 0.6;
  text-decoration: none;
}

.form-know-you-item-button-gray:hover {
  opacity: 0.6;
  text-decoration: none;
}

.label-input-left,
.label-input-right {
  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.label-input-right {
  padding-right: 130px;
}
.label-input-left {
  padding-left: 10px;
}

@media (min-width: 1200px){
  .form-know-you-item-input-smaller {
    width: 180px;
    display: inline-flex !important;
  }

}

@media (min-width: 978px) and (max-width: 1199px){
  .flex-item-right-simulator {
    margin: 0px 60px 30px 30px !important;
  }
  .form-know-you-item-input-smaller {
    width: 180px;
    display: inline-flex !important;
  }
}

.flex-container-simulator {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.flex-item-left-simulator {
  padding: 10px;
  flex: 40%;
  text-align: left;
}
.borderRadius{
  border-radius: 500px;
}

.flex-item-right-simulator {
  flex: 60%;
  margin: 0px 320px 30px 30px;
}

@media (min-width: 360px) and (max-width: 850px){
  .form-know-you-item-input-text-area-mobile {
    width: 240px;
  }
}

.mainspinner {
  position: absolute;
  
}


