@import '../../../assets/styles/brand-colors';
@import '../../../assets/styles/font-styles';

.dropdown-menu[data-bs-popper] {
    left: -120px !important;
}

.bg-light {
    background-color: transparent !important;
}

.nav-link {
    color: white !important;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    height: 130px !important;
}