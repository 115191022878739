* {
  box-sizing: border-box;
}

.flex-container-catalog {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
}

.flex-item-left-catalog {
  padding: 20px;
}

.flex-item-right-catalog {
  padding: 20px;
  flex: 50%;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 800px) {
  .flex-container-catalog {
    flex-direction: column;
  }
}

.title-model {
  font-family: Winter_Poppins;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0;
  text-align: center;
  color: #000000;
}

.title-motos-catalog {
  top: 136px;
  font-family: 'Bebas Neue', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 128%;
  /* or 77px */

  text-align: center;

  color: #4FAE32;


}

.subtitle-motos-catalog {

  font-family: 'Poppins', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 128%;
  /* or 31px */

  text-align: center;

  color: #050713;
}

.form-control-credit-catalog {
  box-sizing: border-box;

  position: absolute;
  left: 63.44%;
  right: 3.59%;
  top: 30.55%;
  bottom: 64.68%;

  border-radius: 100px;
}

.form-control-search-catalog {
  height: 36px;
  border-radius: 18px;
  background: white;
  border: 1px solid #AEAEAE;
  box-shadow: 0 0 2px rgba(31, 28, 28, 0.704);
  margin: 8px;
  padding-left: 10px;
  font-size: 14px;
}
.buttonSearch{
  color: white;
  background: #1059A9;
  border-radius: 25px;
  border:0px;
  padding:0px 30px;
}