.change-pass{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  .header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    h1{
      font-weight: 600;
      font-size: 40px;
      line-height: 33px;
      text-align: center;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'onum' on;
      color: #605E5C; 
      margin: 10px; 
    }
    h5{
        font-weight: 400;
        font-size: 20px;
        line-height: 33px;
        text-align: justify;
        text-transform: lowercase;
        font-feature-settings: 'pnum' on, 'onum' on;
        color: #605E5C;
        margin: 10px;
    }
    @media (max-width:768px) {
      h1{
        font-size: 28px;
      }
      h6{
        font-size: 16px;
      }
    }
  }
  form{
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
    ul{
      display: flex;
      flex-direction: column;
      margin: 10px 0px;
      padding-left: 0px;
      label{
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #4FAE32;
        margin: 5px 0px;
        text-align: left;
      }
      input{
        width: 100%;
        height: 30px;
        padding: 10px;
        border: none;
        border-bottom: 1px solid rgba(147, 139, 139, 0.16);
        &:focus{
          outline: none;
        }
      }
    }
    .password-change{
      text-align: left;
      margin: 10px;
      button{
        border: none;
        color: #fff;
        font-weight: 600;
        border-radius: 29px;
        padding: 10px 45px;
        background: #4FAE32;
        margin: 10px;
      }
    }
  }
  @media (max-width: 768px) {
    .header{
      width: 100%;
    }
    form{
      width: 100%;
    }
    .password-change{
      padding-left: 0px;
      button{
        width: 100%;
        margin: 10px 0px !important;
      }
    }
  }
}