.solicDoc{
    text-align: left;
    padding: 50px 100px;
    .solicDocument{
        @media (max-width: 768px) {
            button{
                margin-right: auto;
                margin-left: 10px;
            }
        }
        h4{
            text-align: center;
            font-weight: 500;
            font-size: 36px;
            line-height: 54px;
            color: #4FAE32;
        }
        .solicTable{
            padding: 20px 0px;
            .search form{
                margin: 0px 0px 20px;
                display: flex;
                justify-content: space-between;
            }
            @media (max-width: 768px) {
                .search form{
                    flex-direction: column;
                }
            }
        }
    }
}
li{
    width: 30%;
    position: relative;
    list-style: none;
    select{
        width: 100%;
        appearance: none;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    input{
        width: 100%;
        padding-left: 30px;
        border-radius: 20px;
        height: 30px;
        &::placeholder{
            font-family: 'SegoeUI';
            font-size: 14px;
            line-height: 16px;
            color: #A19F9D;
        }
        &:focus{
            outline: none;
            box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
        }
    }
    svg{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.pageNum li svg{
    position: static;
}

select{
    width: 100%;
    appearance: none;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
input{
    width: 100%;
    padding-left: 30px;
    border-radius: 20px;
    height: 30px;
    &::placeholder{
        font-family: 'SegoeUI';
        font-size: 14px;
        line-height: 16px;
        color: #A19F9D;
    }
    &:focus{
        outline: none;
        box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.07), -4px -4px 13px #fff, 6px 6px 30px rgba(0, 0, 0, 0.05);
    }
}
li:nth-child(2){
    svg{
        left: auto;
        right: 15px;
        top: 10px;
    }
}
@media (max-width: 768px) {
    li{
        width: 100%;
        margin-bottom: 10px;
    }
}
.responsiveAuto{
    ul{
        display: flex;
        li{
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.08px;
            color: #343741;
            border-bottom: 1px solid #D3DAE6;
            padding: 5px 10px;
            span{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 5px;
            }
            button{
                color:white;
                margin-right: 10px;
                background: #4FAE32;
                border-radius: 100px;
                border: none;
                font-size: medium;
                font-weight: bolder;
            }
        }
        li.firstItem{
            color: #006BB4;
        }
        li.listHeader{
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.24px;
            color: #1A1C21;
        }
    }
}
@media (max-width: 768px) {
    .responsiveAuto{
        width: 400%;;
    }
}
@media (max-width: 768px) {
    .solicDoc{
        padding: 20px 10px;
        margin-top: 10px;
        .solicDocument{
            margin-top: 10px;
        }
    }
}