.backLayer{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background: #00000098;
}
.modals{
    background: #fff;
    color: #000000;
    padding: 30px;
    font-weight: 700;
    position: absolute;
    border-radius: 18px;
    width: 50%;
    left: 26%;
    z-index: 10001;
    .header{
        display: flex;
        justify-content: center;
        h4{
            font-weight: 600;
            font-size: 30px;
            line-height: 45px;
            text-align: center;
            color: #4FAE32;
        }
    }
    .body{
        ul{
            padding-left: 0rem;
            display: flex;
            justify-content: space-between;
            li{
                text-align: left;
                margin-right: 10px;
                input{
                    border: 1px solid #A19F9D;
                }
            }
        }
        .submitt{
            margin: 10px 0px;
            display: flex;
            justify-content: space-between;
            button{
                background-color: #1059A9;
                color: #fff;
                height: 36px;
                border-radius: 18px;
                border: none;
                justify-content: space-between;
                &:first-child{
                    background-color: #4FAE32;
                }
            }
        }
    }
}


.w-10{
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-40{
    width: 40%;
}
.w-50{
    width: 50%;
}
.w-60{
    width: 60%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.w-90{
    width: 90%;
}
.w-100{
    width: 100%;
}

@media screen and (max-width: 768px) {
    .modals{
        left: 0%;
        width: 100%;
        .body{
            ul{
                flex-direction: column;
                li{
                    margin-right: 0px;
                }
            }
            .submitt{
                flex-direction: column;
                button{
                    margin: 5px 0px;
                    width: 100%;
                    &:first-child{
                        order: 2;
                    }
                    &:last-child{
                        order: 1;
                    }
                }
            }
        }
    }
    .w-10{
        width: 100% !important;
    }
    .w-20{
        width: 100% !important;
    }
    .w-30{
        width: 100% !important;
    }
    .w-40{
        width: 100% !important;
    }
    .w-50{
        width: 100% !important;
    }
    .w-60{
        width: 100% !important;
    }
    .w-70{
        width: 100% !important;
    }
    .w-80{
        width: 100% !important;
    }
    .w-90{
        width: 100% !important;
    }
    .w-100{
        width: 100% !important;
    }
}