#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #4FAE32;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes animatebottom {
  from {
    bottom: -1px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0
  }
  to {
    bottom: 0;
    opacity: 1
  }
}

// Buttons

.btn-login-inactive {
  width: 145px !important;
  height: 36px !important;
  border-radius: 18px !important;
  border: 3px solid #FFFFFF !important;
  color: white !important;
}



.btn {
  padding: 1px 6px !important;
}

.btn-primary {
  --bs-btn-bg: black !important;
  --bs-btn-border-color: black !important;
  --bs-btn-hover-bg: black !important;
  --bs-btn-hover-border-color: black !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-bg: black !important;
  --bs-btn-active-border-color: black !important;
  --bs-btn-disabled-bg: black !important;
  --bs-btn-disabled-border-color: black !important;
}

#spinner {
  position: absolute !important;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  background-color: #605E5C;
}

#spinner-field {
  z-index: 1;
  width: 120px;
  height: 120px;
  background-color: #605E5C;
}

#spinner-simulator {
  z-index: 1;
  background-color: #605E5C;
  width: 520px; 
  margin-left: 210px !important;
}

.modal-dialog {
  margin: auto !important;
  max-width: 750px !important;
}