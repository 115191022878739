.mansaje{
    .mansajeBody{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70vh;
        h4{
            font-size: 45px;
            line-height: 111.5%;
            color: #FFFFFF;  
            margin: 10px 0px 5px;  
        }
        h1{
            font-family: 'Bebas Neue';
            font-style: normal;
            font-weight: 400;
            font-size: 200px;
            line-height: 111.5%;
            color: #FFFFFF; 
            margin: 5px 0px 10px;   
        }
    }
    @media (max-width: 768px) {
        h4{
            font-size: 36px;
        }
        h1{
            font-size: 100px;
        }
    }
}