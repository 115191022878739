.flex-container-modals {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px 20px 20px 10px;
}

.flex-container-modals > div {
  background-color: #f1f1f1;
  margin: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 30px;
  display: flex;
}

.input-code-general {
  width: 40px;
  height: 78px;
  background: #69B644;
  border-radius: 17px;
  text-align: center;
  padding-left: 0px;
  color:#FFFFFF;
}


.modal-otp {
  width: 532px;
  height: 400px;
  left: 25%;
  top: 221px;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 71px;
}

.button-modal-accept {
  box-sizing: border-box;

  position: absolute;
  width: 145px;
  height: 36px;
  left: 547px;
  top: 502px;

  border: 3px solid #4FAE32;
  border-radius: 18px;
}

.button-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.color-red {
  color: #4FAE32;
}

.button-red {
  background-color: #fff;
  border: 3px solid #4FAE32;
}

.color-green {
  color: green;
}

.button-green {
  background-color: #fff;
  border: 3px solid green;
}

.button-red,
.button-green {
  max-width: 230px;
  width: calc(100% - 30px);
}

.modal {
  height: auto;
  left: 50%;
  max-width: 90vw;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-dialog {
  margin: 0;
  max-width: 100%;
}

.modal-header {
  padding-right: 25px;
  transform: translateY(10px);
}

@media (MAX-WIDTH: 500) {
  .modal-otp {
    left: 0%;
}
  
}